import React, { ReactElement } from "react";

import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import "antd/dist/reset.css";
import "@/styles/styles.scss";

interface MyAppProps {
  Component: React.FC;
  pageProps: any;
}

export default function MyApp({ Component, pageProps }: MyAppProps): ReactElement {
  return <Component {...pageProps} />;
}
